import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

// Components
import SEO from '../components/seo'
import Layout from '../components/layout/layout'
import Title from '../components/global/title'
import Plan from '../components/plans/plan'

// Helpers
import buildQuoteData from '../helpers/build-quote'
import { ageBrackets } from '../helpers/age-brackets'
import { calculateAge } from '../helpers/calculate-age'

// PDFs
import standardPdf from '../data/standard.pdf'
import primePdf from '../data/prime.pdf'
import fullPdf from '../data/full.pdf'

// Services
import { getLocalUser } from '../services/auth'
import { EventEmitter } from '../services/events'
import firebase from '../services/firebase'

// Apollo
import { useQuery, gql } from '@apollo/client'

const PlansPage = ({ location }) => {
  const [selected, setPlanSelected] = useState({})
  const [planData, setPlanData] = useState([])
  const [user, setUser] = useState()

  useEffect(() => {
    const loadUser = async () => {
      const { userId } = getLocalUser()
      if (userId) {
        let userQuery = await firebase.db
          .collection('users')
          .doc(userId)
          .get()
        let userQuoteQuery = await firebase.db
          .collection('user-quotes')
          .doc(userId)
          .get()
        setUser({ ...userQuery.data(), dob: userQuoteQuery.data().dob })
      }
    }
    loadUser()
  }, [])

  let bracket = '18-60'
  let GET_QUOTES
  let plans = `
            standard
            plus
            full
        `
  const locationState = location?.state || {}
  const { quoteData, quote: onQuote, refCode } = locationState

  let { age } = locationState
  if (!age && user) {
    age = calculateAge({ dob: user.dob, isFirebaseDate: true })
  }

  // If the user is quoting, he/she must have entered their age in the quote form
  if (onQuote || user) {
    if (age && age >= 20 && age <= 64) {
      bracket = ageBrackets.reduce(
        (prev, curr) => (age <= curr.max && !prev ? curr.value : prev),
        ''
      )
    } else if (!user) {
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '¡Lo sentimos!',
        message: 'Debes tener entre 20 y 64 años para ser cliente de Alex.',
        navigateTo: '/',
      })
    }
  }

  // GraphQL query to obtain plan data
  GET_QUOTES = gql`
        query getQuote($bracket: ID!) {
            quote(id: $bracket) {
                    ${plans}
                    standardFeatures {
                        accident
                        travel
                        natural
                        funerary
                        partial_disability
                        total_disability
                        dismemberment
                        accidental_percent
                    }
                    plusFeatures {
                        accident
                        travel
                        natural
                        funerary
                        partial_disability
                        total_disability
                        dismemberment
                        accidental_percent
                    }
                    fullFeatures {
                        accident
                        travel
                        natural
                        funerary
                        partial_disability
                        total_disability
                        dismemberment
                        accidental_percent
                    }
            }
        }
    `
  //Showing and hiding loading modal
  EventEmitter.dispatch('toggleLoading', { show: true })
  let { loading, error, data } = useQuery(GET_QUOTES, {
    variables: { bracket },
  })
  if (data && data.quote)
    EventEmitter.dispatch('toggleLoading', { show: false })

  useEffect(() => {
    if (data && data.quote) {
      let formattedData = buildQuoteData(data.quote)
      let plusPlan = formattedData.find(p => p.name === 'Prime')
      setPlanSelected(plusPlan)
      setPlanData(formattedData)
    }
  }, [data, loading])

  if (error) {
    EventEmitter.dispatch('toggleLoading', { show: false })
    EventEmitter.dispatch('toggleModal', {
      show: true,
      success: false,
      title: '¡Lo sentimos!',
      message: 'En este momento no podemos darte una cotización',
    })
  }

  const getPdf = name => {
    switch (name) {
      case 'Standard':
        return standardPdf
      case 'Prime':
        return primePdf
      case 'Alex Full':
        return fullPdf
      default:
        break
    }
  }

  let planButtonText
  let onPlanButtonClick
  if (user) {
    onPlanButtonClick = async plan => {
      const { userId } = user
      EventEmitter.dispatch('toggleLoading', { show: true })
      await firebase.db
        .collection('/policies')
        .doc(userId)
        .set({ userId, ...plan })
      EventEmitter.dispatch('toggleLoading', { show: false })
      navigate('/users/home')
    }
    planButtonText = 'Cambiar'
  } else if (onQuote) {
    onPlanButtonClick = plan => {
      setPlanSelected(plan)
      navigate('/auth/register', {
        state: { selected: plan, quoteData, refCode },
      })
    }
    planButtonText = 'Seleccionar'
  }

  return (
    <>
      <Layout>
        <SEO title="Planes" />
        <div className="grid grid-cols-12">
          <Title
            main={onQuote ? 'Precios para ti' : 'Planes'}
            subTitle={
              onQuote
                ? 'Estos son los planes que Alex te ofrece acorde a tu cotización. Elige uno:'
                : 'Escoge la opción que mejor se adapta a tus necesidades'
            }
          />
          <div className="col-span-12 xl:col-start-3 xl:col-span-10 mb-20">
            <div className="grid grid-cols-12 gap-2">
              {planData && planData.length ? (
                planData.map((pl, i) => {
                  return (
                    <div
                      key={i}
                      className="col-start-2 col-span-10 mb-10 lg:mb-0 lg:col-span-3"
                    >
                      <Plan
                        onSelect={setPlanSelected}
                        cardSelected={selected}
                        quote={onQuote || !!user}
                        index={i}
                        plan={pl}
                        pdf={getPdf(pl.name)}
                        noButton={user && user.payed}
                        buttonText={planButtonText}
                        onButtonClick={() => onPlanButtonClick(pl)}
                      />
                    </div>
                  )
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PlansPage
